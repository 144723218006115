<template>
  <div class="flex-1">
    <div
      class="flex flex-row flex-wrap w-full sm:text-sm border border-gray-300 rounded-md px-3 py-1 focus:ring-blue-500 focus:border-blue-500 space-x-2 space-y-2 shadow-sm"
      ref="tagHolder"
      :class="{
        'ring-1 border-1 ring-blue-500 border-blue-500': tagHolderFocused,
      }"
    >
      <div
        class="-mx-1 py-1"
        @click="
          () => {
            $refs.inputTag.focus();
            tagHolderFocused = true;
          }
        "
      >
        <span
          class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-800 m-1"
          v-for="(tag, key) in tags"
          v-bind:key="key"
        >
          {{ tag }}
          <button
            type="button"
            class="flex-shrink-0 mt-0.5 -mr-0.5 ml-2 inline-flex text-gray-500 focus:outline-none focus:text-gray-700"
            aria-label="Remove large badge"
            v-on:click="removeTag(key)"
          >
            <i class="fa-solid fa-circle-xmark"></i>
          </button>
        </span>
        <span
          class="inline-flex items-center py-0.5 text-sm font-medium leading-5 text-gray-800 m-1"
        >
          <input
            id="project_name"
            class="flex-shrink-0 inline-flex text-gray-700 focus:outline-none"
            placeholder="Add tag"
            ref="inputTag"
            v-model="input"
            v-on:input="processTags($event.target.value)"
            v-on:keydown.tab.prevent="
              processTagsOnKeyUpEvent($event.target.value)
            "
            v-on:keydown.enter.prevent="
              processTagsOnKeyUpEvent($event.target.value)
            "
            @focusout="tagHolderFocused = false"
            @click="
              () => {
                $refs.inputTag.focus();
                tagHolderFocused = true;
              }
            "
          />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TagInput",
  props: {
    resId: {
      type: String,
    },
    tags: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      input: null,
      tagHolderFocused: false,
    };
  },
  methods: {
    processTags(value) {
      if (value.length > 0 && value.indexOf(",") > -1) {
        let tags = value.split(",");
        console.log(tags);

        // tags.forEach(tag => {
        //   if (tag.length > 0) {
        //     this.emailDomains = this.addIfUnique(
        //       this.emailDomains,
        //       this.parsedTag(tag)
        //     );
        //   }
        // });
      }
    },
    processTagsOnKeyUpEvent(value) {
      if (!value.replace(/\s/g, "").length) return;
      if (value.length > 0) {
        this.addIfUnique(value);
      }
    },
    removeTag(index) {
      this.tags = this.tags.splice(index, 1);
    },
    hasDuplicates(arr, addVal) {
      const findDups = arr.filter(
        (item, index) => item.toLowerCase() == addVal.toLowerCase()
      );
      if (findDups.length > 0) return true;
      return false;
    },
    addIfUnique(value) {
      if (!this.hasDuplicates(this.tags, value)) {
        this.tags.push(value);
      }
      this.input = null;
    },
  },
};
</script>
