var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1"},[_c('div',{ref:"tagHolder",staticClass:"flex flex-row flex-wrap w-full sm:text-sm border border-gray-300 rounded-md px-3 py-1 focus:ring-blue-500 focus:border-blue-500 space-x-2 space-y-2 shadow-sm",class:{
      'ring-1 border-1 ring-blue-500 border-blue-500': _vm.tagHolderFocused,
    }},[_c('div',{staticClass:"-mx-1 py-1",on:{"click":function () {
          _vm.$refs.inputTag.focus();
          _vm.tagHolderFocused = true;
        }}},[_vm._l((_vm.tags),function(tag,key){return _c('span',{key:key,staticClass:"inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-800 m-1"},[_vm._v(" "+_vm._s(tag)+" "),_c('button',{staticClass:"flex-shrink-0 mt-0.5 -mr-0.5 ml-2 inline-flex text-gray-500 focus:outline-none focus:text-gray-700",attrs:{"type":"button","aria-label":"Remove large badge"},on:{"click":function($event){return _vm.removeTag(key)}}},[_c('i',{staticClass:"fa-solid fa-circle-xmark"})])])}),_c('span',{staticClass:"inline-flex items-center py-0.5 text-sm font-medium leading-5 text-gray-800 m-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],ref:"inputTag",staticClass:"flex-shrink-0 inline-flex text-gray-700 focus:outline-none",attrs:{"id":"project_name","placeholder":"Add tag"},domProps:{"value":(_vm.input)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.input=$event.target.value},function($event){return _vm.processTags($event.target.value)}],"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return _vm.processTagsOnKeyUpEvent($event.target.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.processTagsOnKeyUpEvent($event.target.value)}],"focusout":function($event){_vm.tagHolderFocused = false},"click":function () {
              _vm.$refs.inputTag.focus();
              _vm.tagHolderFocused = true;
            }}})])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }