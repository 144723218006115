<template>
  <div>
    <transition
      enter-active-class="transition-all ease-out-quad duration-500"
      leave-active-class="transition-all ease-in-quad duration-500"
      enter-class="opacity-0 blur-0"
      enter-to-class="opacity-100 blur-100"
      leave-class="opacity-100 blur-100"
      leave-to-class="opacity-0 blur-0"
    >
      <div
        class="justify-center sm:items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none blur"
        v-if="isOpen"
      >
        <div class="w-full h-full absolute" style="z-index:100"></div>
      </div>
    </transition>

    <transition
      enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div class="fixed inset-0 overflow-hidden z-50" v-if="isOpen">
        <div class="absolute inset-0 overflow-hidden" @click.self="close">
          <section
            class="absolute inset-y-0 right-0 sm:pl-10 max-w-full flex sm:pl-16"
          >
            <div class="w-screen max-w-2xl">
              <div
                class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <header class="px-4 py-6 bg-gray-800 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <h2 class="text-lg leading-7 font-medium text-white">
                          Upload a video
                        </h2>
                        <p class="text-sm text-gray-300 leading-5">
                          Select the video that you to publish, add a title and
                          description, add tags, set a schedule time (if
                          applicable), and select your destinations.
                        </p>
                      </div>
                      <div class="h-7 flex items-center">
                        <button
                          class="p-1 border-2 border-transparent rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 text-gray-400 transition ease-in-out duration-150"
                          aria-label="Close panel"
                          v-on:click="close"
                        >
                          <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </header>

                  <!-- <video></video> -->

                  <!-- Divider container -->
                  <div
                    class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200"
                  >
                    <!-- Project name -->
                    <div class="space-y-1 p-4">
                      <div class="DashboardContainer"></div>
                    </div>

                    <!-- Project description -->
                    <div
                      class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="title"
                          class="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Title
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          v-model="title"
                        />
                      </div>
                    </div>

                    <!-- Project description -->
                    <div
                      class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="description"
                          class="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Description
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <textarea
                          type="text"
                          rows="5"
                          name="description"
                          id="description"
                          class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          v-model="description"
                        ></textarea>
                      </div>
                    </div>

                    <!-- Tags -->
                    <div
                      class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="project_tags"
                          class="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Tags
                        </label>
                      </div>
                      <div class="sm:col-span-2 flex flex-wrap">
                        <TagInput resId="project_tags" :tags="tags" />
                      </div>
                    </div>

                    <!-- Destinations -->
                    <div
                      class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="project_description"
                          class="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Destinations
                        </label>
                        <p
                          class="text-red-500 text-sm leading-small"
                          v-if="
                            actionRequired !== null &&
                              actionRequired.entityExpired
                          "
                        >
                          One or more of your destinations require attention.
                          Visit the
                          <span
                            class="text-blue-600 cursor-pointer"
                            v-on:click="
                              () => {
                                close();
                                $router.push({ name: 'AppSettings' });
                              }
                            "
                            >settings
                          </span>
                          page to view issues and make changes.
                        </p>
                      </div>
                      <div class="sm:col-span-2 flex flex-col space-y-4">
                        <p
                          class="text-sm mt-2"
                          v-if="socialAccounts.length == 0"
                        >
                          No destinations currently exist.
                          <a class="text-blue-600" href="#">Add</a> some to get
                          started posting your content!
                        </p>
                        <div
                          class="group relative rounded-lg shadow-sm cursor-pointer focus:outline-none"
                          v-for="account in socialAccounts"
                          v-bind:key="account.id"
                          v-on:click="accountSelected(account.id)"
                          :class="{ 'cursor-not-allowed': account.isExpired }"
                        >
                          <!-- focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md -->
                          <div
                            class="rounded-lg bg-white px-6 py-4 spacec-y-4"
                            :class="{
                              'border-2 ring-blue-500 border-blue-500': selectedIds.includes(
                                account.id
                              ),
                              'border border-gray-300': !selectedIds.includes(
                                account.id
                              ),
                              'bg-gray-50': account.isExpired,
                            }"
                            v-bind:key="account.id + account.active"
                          >
                            <div class="space-x-4 flex flex-row items-center">
                              <div class="w-8 flex justify-center">
                                <img
                                  class="w-4 h-4"
                                  src="https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512"
                                  v-if="account.type == 'facebook'"
                                />
                                <img
                                  class="w-auto h-4"
                                  src="@/assets/3rd-party/yt-icon.png"
                                  v-if="account.type == 'youtube'"
                                />
                                <img
                                  class="w-auto h-4"
                                  src="@/assets/3rd-party/2021-twitter-logo-blue.png"
                                  v-if="account.type == 'twitter'"
                                />
                              </div>

                              <div class="flex flex-col">
                                <p class="block font-medium text-gray-900">
                                  {{ account.display_name }}
                                </p>
                                <div class="text-gray-500">
                                  <span class="block sm:inline text-sm">{{
                                    account.created_at | displayDateTime
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="
                                account.type == 'youtube' &&
                                  selectedIds.includes(account.id)
                              "
                            >
                              Privacy Settings
                              <select
                                id="country"
                                name="country"
                                autocomplete="country-name"
                                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                v-bind:value="ytPrivacy"
                              >
                                <option value="public">Public</option>
                                <option value="unlisted">Unlisted</option>
                                <option value="private">Private</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Watermarks -->
                    <div
                      class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="project_description"
                          class="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Watermark
                        </label>
                      </div>
                      <div
                        class="sm:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4"
                      >
                        <p class="text-sm mt-2" v-if="watermarks.length == 0">
                          No watermarks currently exist.
                          <a class="text-blue-600" href="#">Add</a> some to
                          apply your watermark!
                        </p>
                        <div
                          class="bg-gray-50 p-2 rounded-lg overflow-hidden shadow-md border border-gray-300  cursor-pointer"
                          :class="{
                            'ring-1 border-1 ring-blue-500 border-blue-500':
                              watermark.id == watermarkId,
                          }"
                          v-for="watermark in watermarks"
                          v-bind:key="watermark.id"
                          v-on:click="watermarkId = watermark.id"
                        >
                          <div
                            class="flex flex-row items-center content-center justify-between rounded"
                          >
                            <div class="flex flex-row space-x-2">
                              <div
                                class="inline-flex justify-center items-center w-6 h-6 bg-blue-600 text-white rounded-full"
                                v-if="defaultWatermarkId == watermark.id"
                              >
                                <i class="fa-solid fa-star text-xs fa-fw"></i>
                              </div>
                              <h2 class="font-semibold">
                                {{
                                  watermark.name == null ||
                                  watermark.name.length == 0
                                    ? "Untitled"
                                    : watermark.name
                                }}
                              </h2>
                            </div>
                          </div>
                          <div
                            class="bg-checkered mt-2 rounded-lg flex items-center content-center justify-center overflow-hidden relative select-none"
                          >
                            <img
                              class="w-auto h-28 p-6"
                              :style="{ opacity: watermark.opacity / 100 }"
                              :src="watermark.publicImageUrl"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="p-4 text-sm text-gray-400">
                      <ul class="list-disc pl-4">
                        <li>
                          By clicking 'upload,' you certify that the content you
                          are uploading complies with the YouTube Terms of
                          Service (including the YouTube Community Guidelines)
                          at
                          <a
                            href="https://www.youtube.com/t/terms"
                            class="text-blue-400"
                            >https://www.youtube.com/t/terms</a
                          >. Please be sure not to violate others' copyright or
                          privacy rights.
                        </li>
                        <li>
                          If you upload content to YouTube websites,
                          applications, services or products that is Made for
                          Kids, then you must immediately go to YouTube on
                          desktop to declare your content as Made for Kids.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Action buttons -->
                <div
                  class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6"
                >
                  <div class="space-x-3 flex justify-end">
                    <span class="inline-flex rounded-md shadow-sm">
                      <button
                        type="button"
                        class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                      >
                        Cancel
                      </button>
                    </span>
                    <span class="inline-flex rounded-md shadow-sm">
                      <button
                        type="submit"
                        class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                        v-on:click="createVideo"
                      >
                        Create
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { Upload } from "tus-js-client";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import GoogleDrive from "@uppy/google-drive";
import Dropbox from "@uppy/dropbox";
import OneDrive from "@uppy/onedrive";
import Tus from "@uppy/tus";
import axios from "axios";

import TagInput from "@/components/TagInput";

const uuidv5 = require("uuid/v5");
const MY_NAMESPACE = "1b671a64-40d5-491e-99b0-da01ff1f3341";

const CompanionUrl = "https://upload-companion.cloudsprouter.com";
const TusdUrl = "https://upload-tusd.cloudsprouter.com/files/";

export default {
  name: "AuthLogin",
  data() {
    return {
      uppy: null,
      input: null,

      title: null,
      description: null,
      selectedIds: [],
      uploadId: null,
      watermarkId: null,
      tags: [],
      ytPrivacy: "public",
    };
  },
  components: { TagInput },
  props: {
    isOpen: Boolean,
  },
  computed: {
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      socialAccounts: (state) => state.socialAccounts,
      actionRequired: (state) => state.actionRequired,
      defaultWatermarkId: (state) => state.defaultWatermarkId,
      watermarks: (state) => state.watermarks,
    }),
  },
  mounted() {
    if (this.isOpen) {
      setTimeout(
        function() {
          this.initializeUppy();
        }.bind(this),
        200
      );
    }
    this.watermarkId = this.defaultWatermarkId;
  },
  watch: {
    isOpen(value) {
      if (value) {
        setTimeout(
          function() {
            this.initializeUppy();
          }.bind(this),
          200
        );
        return document.querySelector("body").classList.add("overflow-hidden");
      }
      setTimeout(
        function() {
          this.uppy.close();
        }.bind(this),
        700
      );
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    defaultWatermarkId: function(val) {
      this.watermarkId = val;
    },
  },
  created() {
    let self = this;
    document.addEventListener("keyup", (event) => {
      if (self.isOpen && event.keyCode === 27) self.close();
    });
  },
  beforeDestroy() {
    document.removeEventListener("keyup", (event) => {
      if (self.isOpen && event.keyCode === 27) self.close();
    });
    this.uppy.close();
  },
  methods: {
    close() {
      if (this.isOpen) this.$emit("closePopover");
    },
    initializeUppy() {
      this.uppy = new Uppy({
        debug: true,
        autoProceed: false,
        restrictions: {
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ["video/*"],
        },
      })
        .use(Dashboard, {
          inline: true,
          target: ".DashboardContainer",
          replaceTargetContent: true,
          showProgressDetails: true,
          note: "Video only, 1 file, unlimited size.",
          height: 300,
          metaFields: [{ id: "name", name: "Name", placeholder: "file name" }],
          browserBackButtonClose: true,
        })
        .use(GoogleDrive, {
          target: Dashboard,
          companionUrl: CompanionUrl,
        })
        .use(Dropbox, {
          target: Dashboard,
          companionUrl: CompanionUrl,
        })
        .use(OneDrive, {
          target: Dashboard,
          companionUrl: CompanionUrl,
        })
        .use(Tus, { endpoint: TusdUrl });

      var self = this;
      this.uppy.on("file-added", (file) => {
        console.log(file);

        // let blobURL = URL.createObjectURL(file.data);
        // document.querySelector("video").src = blobURL;
        self.uploadId = uuidv5(file.id, MY_NAMESPACE);
        this.uppy.setFileMeta(file.id, {
          uploadId: self.uploadId,
        });
      });

      this.uppy.setMeta({
        teamId: this.currentTeamId,
      });

      this.uppy.on("complete", (result) => {
        console.log("successful files:", result.successful);
        console.log("failed files:", result.failed);
      });
    },
    accountSelected(id) {
      let filteredAccount = this.socialAccounts.find((result) => {
        return result.id == id;
      });
      if (filteredAccount.isExpired) {
        return;
      }
      if (this.selectedIds.includes(id)) {
        this.selectedIds.splice(this.selectedIds.indexOf(id), 1);
        return;
      }
      this.selectedIds.push(id);
      console.log(this.selectedIds);
    },
    async createVideo() {
      if (this.uploadId == null) {
        alert(
          "You must first select a video and start an upload before you can submit."
        );
        return;
      }
      let res = await axios.post(
        process.env.VUE_APP_API_URL +
          "/app/team/" +
          this.currentTeamId +
          "/posts?type=video",
        {
          title: this.title,
          description: this.description,
          connectionIds: this.selectedIds,
          uploadId: this.uploadId,
          watermarkId: this.watermarkId,
        }
      );
      return res.data;
    },
  },
};
</script>

<style>
.blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
}
.blur-0 {
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  background-color: rgba(0, 0, 0, 0.2);
}
.blur-100 {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
